import baseAxios from '@/api/base';

baseAxios.defaults.baseURL = `${process.env.VUE_APP_APIPATH}`;
/**
 * 取得預約講堂列表
 */
export const GetBookingList = () => baseAxios.post('FrontEnd/Bookings/BookingList');

/**
 * 取得預約講堂資料
 * @param {object} data { id }
 */
export const GetBookingById = (id) => baseAxios.post('FrontEnd/Bookings/BookingById', { id });

/**
 * 報名預約講堂
 * @param {object} data { bookingId }
 */
export const SaveBookingSignUp = (data) => baseAxios.post('FrontEnd/Bookings/SaveBookingSignUp', data);
