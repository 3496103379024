<template>
  <section class="container pb-8 " v-if="BookingData.length !== 0">
    <ul>
      <li
        v-for="item in BookingData"
        :key="item.bookingId"
        class="mb-3 mb-md-6 p-3 p-md-6 bg-light rounded border-shadow"
      >
        <div class="row">
          <div class="col-12 col-md-6 position-relative bg-outer mb-3 mb-md-0">
            <div
              class="bg-image rounded"
              :style="{
                paddingBottom: '50%',
                backgroundImage: 'url(' + item.bookingImgPath + ')',
              }"
            ></div>
          </div>
          <div
            class="col-12 col-md-6 col-lg-5 mx-md-auto d-flex
            flex-column justify-content-between align-self-stretch"
          >
            <h5 class="text-ellipsis mt-0 mb-1">{{ item.bookingName }}</h5>
            <div class="cs-table" data-style="7">
              <dl>
                <dt>剩餘人數：</dt>
                <dd>
                  <span class="mr-1">{{ item.bookingLimit - item.bookingCount }}</span
                  >人
                </dd>
              </dl>
              <dl>
                <dt>講堂日期：</dt>
                <dd>{{ item.bookingDate | toTaipei | moment('YYYY-MM-DD') }}</dd>
              </dl>
              <dl>
                <dt>報名時間：</dt>
                <dd>
                  {{ item.bookingStartDate | toTaipei | moment('YYYY-MM-DD') }} -
                  {{ item.bookingEndDate | toTaipei | moment('YYYY-MM-DD') }}
                </dd>
              </dl>
            </div>
            <div class="row" v-if="item.bookingLimit - item.bookingCount > 0">
              <div class="col-6">
                <button
                  class="btn btn-primary text-white w-100"
                  @click="SaveBookingSignUp(item.bookingId)"
                >
                  我要申請報名
                </button>
              </div>
              <div class="col-6">
                <router-link
                  class="btn btn-outline-primary w-100"
                  :to="`/bookings/${item.bookingId}`"
                >
                  詳細資訊
                </router-link>
              </div>
            </div>
            <div class="row" v-if="item.bookingLimit - item.bookingCount === 0">
              <div class="col-12">
                <div href="" class="btn btn-outline-danger disabled w-100">已額滿</div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </section>
  <section class="container pb-8 " v-else>
    <div class="container">
      <div class="error-bg mb-3 mb-md-8">
        <div>暫無講堂資料</div>
      </div>
    </div>
  </section>
</template>

<script>
import { GetBookingList, SaveBookingSignUp } from '@/api/BookingApi';

export default {
  data() {
    // 建立你的初始化 model data
    return {
      BookingData: [],
    };
  },
  created() {
    this.GetBookingList();
  },
  methods: {
    GetBookingList() {
      GetBookingList().then((response) => {
        if (response.data.statusCode === 0) {
          this.BookingData = response.data.data;
        }
      });
    },
    SaveBookingSignUp(bookingId) {
      if (this.$UserAccount === '') {
        this.$message({
          showClose: true,
          type: 'error',
          message: '請先登入再進行報名申請。',
        });
        this.$router.push({ path: '/login', query: { redirect: this.$route.path } });
      } else {
        SaveBookingSignUp({ BookingId: bookingId }).then((response) => {
          if (response.data.statusCode === 0) {
            this.$message({
              showClose: true,
              type: 'success',
              message:
                '已提交報名申請。※報名申請並非報名成功，請注意查收電子信箱是否有報名成功的通知。',
            });
          }
        });
      }
    },
  },
};
</script>

<style></style>
